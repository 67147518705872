

import React from 'react';
import {colors} from '../../style/constants';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  button: {
    position: 'relative',
    color: colors.WHITE,
    display: 'flex',
    width: 290,
    height: 120,
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 30,
    paddingLeft: 40,
    boxSizing: 'border-box',
    '& svg': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    '& span': {
      marginTop: -5
    }
  }
}));


const SearchButton = () => {
  const classes = useStyles();
  return (
    <div className={classes.button}>
      <span>Je recherche</span>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 357.31 114.08">
        <defs>
          <linearGradient id="search-button-gradient" data-name="Dégradé sans nom 19" y1="57.04" x2="357.31" y2="57.04" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#ffc325" stopOpacity="0"/>
            <stop offset="1" stopColor="#ffc325"/>
          </linearGradient>
        </defs>
        <polygon points="354.89 57.04 320.46 6.47 320.4 31.65 2 31.65 2 32.43 2 81.65 2 82.43 320.4 82.43 320.46 107.61 354.89 57.04" fill="none" strokeMiterlimit="10" strokeWidth="4px" stroke="url(#search-button-gradient)"/>
      </svg>
    </div>
  )
}


export default SearchButton;