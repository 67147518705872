import React from 'react';
import Research from '../../components/Research';
import departmentsList from '../../datas/departments';

const Departments = () => {
  return (
    <Research data={departmentsList} dataType='department'/>
  )
}

export default Departments