import React from 'react';
import {colors} from '../../style/constants';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  homeSun: {
    position: 'absolute',
    left: -20,
    bottom: -20,
  },
  researchSun: {
    position: 'absolute',
    right: -5,
    bottom: 70,
  },
  resultSun: {
    position: 'absolute',
    right: -221,
    bottom: -5,
  },
}));

const HomeSun = () => {
  const classes = useStyles();
  return (
    <svg className={classes.homeSun} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 855.81 669.89" width="855px">
      <path d="M855.31,641.85c0-5.63-3.34-11.26-10-12.05L112.84,535.09c-5.69-.69-10-5.87-10-12.06v-1.61a12.09,12.09,0,0,1,8.11-11.68l639-218.66c12.85-4.07,9.57-24.62-3.79-23.8L59.89,309c-6.47.38-11.9-5.15-11.9-12.12v-3.57a12.32,12.32,0,0,1,6.06-10.8L516,23.46C527.52,17,521.39-1.92,508.64.76L18,102.46.5,120.09v549.3H855.31Z" fill="none" stroke={colors.YELLOW} strokeLinecap="square" strokeLinejoin="bevel"/>
    </svg>
  )
}

const ResearchSun = () => {
  const classes = useStyles();
  return (
    <svg className={classes.researchSun} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 431.26 646.47" width="431px">
      <path d="M417.27,51.47,175,.63c-6.41-1.35-9.48,8.17-3.68,11.42L403.75,142.4a6.21,6.21,0,0,1,3.05,5.44v1.79a5.86,5.86,0,0,1-6,6.1L55.44,134.78c-6.72-.42-8.37,9.93-1.9,12l321.58,110a6.12,6.12,0,0,1,4.08,5.88,6,6,0,0,1-5,6.07L5.52,317.11c-6.7.83-6.69,11.36,0,12.16l368.62,47.66a6,6,0,0,1,5,6.07v.81a6.09,6.09,0,0,1-4.08,5.88l-321.58,110c-6.47,2-4.82,12.4,1.9,12l345.37-21a5.87,5.87,0,0,1,6,6.1v1.8a6.21,6.21,0,0,1-3.05,5.43L171.28,634.42c-5.81,3.26-2.73,12.77,3.69,11.42L417.28,595c12.54-2.72,13.48-10.2,13.48-13.13V65c0-2.94-1.19-10.72-13.49-13.52" fill="none" stroke={colors.YELLOW} strokeLinecap="square" strokeLinejoin="bevel"/>
    </svg>
  )
}

const ResultSun = () => {
  const classes = useStyles();
  return (
    <svg className={classes.resultSun} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 442.62 295.38" width="442px">
      <path d="M407.25,285.65,442,119.86c.92-4.39-5.59-6.49-7.82-2.52L345,276.4a4.25,4.25,0,0,1-3.72,2.08h-1.23a4,4,0,0,1-4.17-4.09l14.33-236.3c.29-4.6-6.79-5.73-8.18-1.3l-75.29,220a4.17,4.17,0,0,1-4,2.79,4.09,4.09,0,0,1-4.16-3.43L225.5,3.94c-.56-4.59-7.77-4.58-8.31,0L184.57,256.16a4.08,4.08,0,0,1-4.15,3.44h-.55a4.16,4.16,0,0,1-4-2.79l-75.29-220c-1.41-4.43-8.48-3.3-8.2,1.3l14.35,236.3a4,4,0,0,1-4.18,4.09h-1.22a4.24,4.24,0,0,1-3.72-2.08l-89.18-159c-2.23-4-8.74-1.87-7.82,2.52L35.4,285.66c1.86,8.58,7,9.22,9,9.22H398c2,0,7.34-.82,9.25-9.23" fill="none" stroke={colors.YELLOW} strokeLinecap="square" strokeLinejoin="bevel"/>
    </svg>

  )
}

export {HomeSun, ResearchSun, ResultSun};