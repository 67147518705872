import React from 'react';
import {colors} from '../../style/constants';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {Home, Back} from '../svgs/buttons';
const useStyles = makeStyles(() => ({
  wrapper: {
    background: colors.BLUE,
    position: 'absolute',
    width: '80px!important',
    padding: '25px 0!important',
    height: 160,
    bottom: 135,
    left: -40,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      width: 40
    }
  },
}));

const Research = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <button className="clickable"><Link to="/"><Home color={props.color}/></Link></button>
      <button className="clickable" onClick={props.onClick}>{props.onClick ? <Back color={props.color}/> : <Link to="/"><Back color={props.color}/></Link>}</button>
    </div>
  )
}

export default Research;