import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import {Route, Switch, useHistory } from 'react-router-dom';
import Home from './pages/Home';
import Rtcs from './pages/Rtcs';
import People from './pages/Research/people';
import Departments from './pages/Research/departments';

const App = () => {
  
  const history = useHistory();

  const handleOnIdle = (e) => {
    history.push("/");
  }

  useIdleTimer({
    timeout: 1000 * 60,
    onIdle: handleOnIdle,
    debounce: 500
  })


  return (
    
      <Route render={({ location }) => (
        <Switch location={location}>
          <Route path="/" exact render={() => <Home />} />
          <Route path="/people" exact render={() => <People />} />
          <Route path="/departments" exact render={() => <Departments />} />
          <Route path="/rtcs" exact render={() => <Rtcs />} />
        </Switch>
      )} />
  );
}

export default App;
