import React from 'react';
import {colors} from '../../style/constants';
import { makeStyles } from '@material-ui/core/styles';
import {ResearchSun} from '../svgs/Suns';
import {Cgp} from '../svgs/Logo';
import Box from './box';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  logo: {
    width: 350,
    marginBottom: 100
  }
}));

const Research = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <ResearchSun />
      <Cgp className={classes.logo} primaryColor={colors.WHITE} secondaryColor={colors.YELLOW}/>
      <Box data={props.data} dataType={props.dataType}/>
    </div>
  )
}

export default Research;