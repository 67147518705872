import React from 'react';
import {colors} from '../../style/constants';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  input: {
    background: colors.WHITE,
    width: 'calc(100% - 200px)!important',
    padding: 0,
    margin: '25px 100px 0 100px',
    height: 50,
    textAlign: 'center',
    borderColor: '#2ad7f4!important',
    boxShadow: '0px 0px 10px 0px rgba(42,215,244,0.75)',
    borderStyle: 'solid',
    fontSize: 16,
    color: colors.DARK_GREY,
    '&:focus': {
      borderColor: '#2ad7f4!important',
      outline: 'none',
      boxShadow: '0px 0px 10px 0px rgba(42,215,244,0.75)',
    }
  },
}));

const SearchBar = (props) => {
  const classes = useStyles();
  return (
    <input className={classes.input} type="text" placeholder="Prénom/Nom · Département/Service · Local" defaultValue={props.value}/>
  )
}

export default SearchBar;