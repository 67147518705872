import React from 'react';
import {colors} from '../../style/constants';

const Routes = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.1 20.15">
      <path d="M46.77,16.34c-2.41.62-5,1.41-6,2.18L56.1,18.2a19.67,19.67,0,0,0-4-11C49.57,3.84,45.46.85,38.89,0c3,1.46,7.86,3.69,10.82,7.57h0a11.66,11.66,0,0,1,2.46,6.88.44.44,0,0,1-.2.36,7.15,7.15,0,0,1-1.91.67l-3.3.85" fill={colors.YELLOW}/>
      <path d="M0,10q0,3.35,0,6.69a2.19,2.19,0,0,0,0,.51A1,1,0,0,0,1.15,18h4.3c.43,1.24,2.15,2.19,4.13,2.19h0c2,0,3.72-.94,4.14-2.18H24.59c.42,1.24,2.15,2.19,4.14,2.19h0a5.41,5.41,0,0,0,3-.83A2.7,2.7,0,0,0,32.86,18h3.67c.7,0,1.08-.37,1-1-.11-1.52-.21-3.05-.47-4.57A7,7,0,0,0,35.53,9,5.27,5.27,0,0,0,31,7.2c-9.28,0-18.56,0-27.84,0-2,0-3,.54-3.14,1.87A7.56,7.56,0,0,0,0,10Zm26.54,1.42V8.32c1.6,0,3.13,0,4.66,0a3,3,0,0,1,2.05.63,5.06,5.06,0,0,1,1,1.1A7.25,7.25,0,0,1,35,11.46S26.54,11.49,26.54,11.46Zm-.78,5.92c0-1,1.3-1.91,2.9-1.92s3,.8,3,1.87-1.3,2-2.94,2S25.76,18.42,25.76,17.38Zm-7.34-9h6.85v3.15H18.42Zm-8.11,3.17V8.36h6.84v3.15ZM6.62,17.37c0-1.05,1.34-1.92,3-1.91s3,.85,2.94,1.92-1.34,1.94-3,1.94S6.63,18.43,6.62,17.37ZM1.74,11.28a21.14,21.14,0,0,1,.13-2.39c0-.38.48-.57,1-.57h5.9a1,1,0,0,1,.24,0v3.15H2.18C2,11.51,1.74,11.36,1.74,11.28Z" fill={colors.WHITE}/>
    </svg>
  )
}

const People = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.99 50.34">
      <path d="M19,12.93a7.72,7.72,0,1,1-7.71,7.72A7.72,7.72,0,0,1,19,12.93m0-2a9.72,9.72,0,1,0,9.72,9.72A9.72,9.72,0,0,0,19,10.93Z" fill={colors.LIGHT_BLUE}/>
      <path d="M1,50.34a18,18,0,1,1,36,0" fill="none" stroke={colors.LIGHT_BLUE} strokeMiterlimit="10" strokeWidth="2px"/>
      <path d="M18.4,7,16.78,1.17a.15.15,0,0,0-.29.05l-.22,6.52a.17.17,0,0,1-.09.13l0,0a.15.15,0,0,1-.2-.07L12.52.08a.15.15,0,0,0-.28.09L13.46,8.4a.15.15,0,0,1-.08.16.16.16,0,0,1-.19,0L8,1a.15.15,0,0,0-.26.14l3.11,8.55a.15.15,0,0,1-.08.18h0a.15.15,0,0,1-.17,0l-6-5.76a.15.15,0,0,0-.24.18l4.34,7.27a.15.15,0,0,1-.06.2l0,0a.14.14,0,0,1-.15,0L3,8.22c-.14-.09-.31.08-.21.21l3.83,4.69c.2.24.37.18.44.15L18.25,7.45c.07,0,.22-.14.15-.44" fill={colors.YELLOW}/>
    </svg>
  )
}

const Departments = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 49.43">
      <path d="M19.55,14.83l5.75,10-5.75,10H8.06l-5.75-10,5.75-10H19.55m1.16-2H6.9L0,24.78l6.9,12H20.71l6.9-12-6.9-12Z" fill={colors.LIGHT_BLUE}/>
      <path d="M41.94,2l5.75,10-5.75,10H30.45L24.7,12,30.45,2H41.94M43.1,0H29.29l-6.9,12,6.9,12H43.1L50,12,43.1,0Z" fill={colors.LIGHT_BLUE}/>
      <path d="M41.94,27.52l5.75,10-5.75,10H30.45l-5.75-10,5.75-10H41.94m1.16-2H29.29l-6.9,12,6.9,12H43.1l6.9-12-6.9-12Z" fill={colors.LIGHT_BLUE}/>
      <path d="M46.07,11.4,42.45,8.17c-.09-.09-.24,0-.17.15L45,12.81a.15.15,0,0,1,0,.13l0,0a.11.11,0,0,1-.16,0L39.11,9.27c-.11-.07-.24.09-.15.19l4.38,5a.12.12,0,0,1,0,.14.11.11,0,0,1-.14.06l-6.73-2.79a.12.12,0,0,0-.12.2l5.79,4.42a.13.13,0,0,1,0,.16h0a.1.1,0,0,1-.12.06L35.5,15.45a.12.12,0,0,0-.08.22l6.06,3a.11.11,0,0,1,.05.16l0,0a.13.13,0,0,1-.11.06l-5.22-.06a.12.12,0,0,0,0,.23l4.61,1.51c.24.07.33,0,.36-.09l5.06-8.78s.09-.19-.09-.36" fill={colors.YELLOW}/>
    </svg>
  )
}

export {Routes, People, Departments};