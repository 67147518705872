import React from 'react';

const Cgp = (props) => {
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 69.35">
      <path d="M54.85,8.73a359.34,359.34,0,0,1,53.55,3.78.83.83,0,0,0,.94-.83v-4A4.14,4.14,0,0,0,106,3.34,375,375,0,0,0,54.85,0,386.18,386.18,0,0,0,5.59,3C4.06,3.26,0,4.21,0,8.57V61a5,5,0,0,0,4.53,5.16,384.37,384.37,0,0,0,50.32,3.17A375,375,0,0,0,106.21,66a3.69,3.69,0,0,0,3.13-3.46V57.67a.83.83,0,0,0-.94-.83,359.28,359.28,0,0,1-53.55,3.79A376.15,376.15,0,0,1,11.2,58.18a1.66,1.66,0,0,1-1.71-1.65V12.74A1.64,1.64,0,0,1,11,11.2l.17,0A376.22,376.22,0,0,1,54.85,8.73" fill={props.primaryColor}/>
      <path d="M108.58,19.17,94.88,16.3c-.36-.08-.53.46-.2.64l13.13,7.37a.35.35,0,0,1,.18.31v.1a.33.33,0,0,1-.34.34L88.13,23.88a.35.35,0,0,0-.11.68l18.18,6.21a.36.36,0,0,1,.23.34.34.34,0,0,1-.29.34L85.31,34.18a.35.35,0,0,0,0,.69l20.83,2.69a.35.35,0,0,1,.29.35v0a.36.36,0,0,1-.23.34L88,44.5a.35.35,0,0,0,.11.68L107.65,44a.33.33,0,0,1,.34.34v.1a.35.35,0,0,1-.18.31L94.68,52.12a.34.34,0,0,0,.21.64l13.69-2.87c.71-.16.76-.58.76-.75V19.94c0-.17-.07-.61-.76-.77" fill={props.secondaryColor}/>
      <path d="M68.51,38.62H22.9c-5.42,0-5.25-4.38-5.25-4.38V22.57a5.3,5.3,0,0,1,5-5.41c2.76-.33,19.63-1.25,29.86-1.25,7.59,0,15.07.07,22.35.48,4.8.3,5.2,3.47,5.22,4.3v3a.72.72,0,0,1-.76.72c-8.65-.6-17.64-.73-26.81-.73-8,0-15.69.29-23.32.75A1.47,1.47,0,0,0,27.68,26v3.26a1.39,1.39,0,0,0,1.58,1.39H74.75c5.52,0,5.38,4.51,5.38,4.51V47.27a5.21,5.21,0,0,1-5,5.49c-2.58.26-14.86.59-22.51.59-10.23,0-20.14-.47-29.74-1.21-5.54-.44-5.17-4.26-5.19-4.49V44.71a.69.69,0,0,1,.77-.68c10.89,1,22.32,1.51,34.16,1.51,5.39,0,10.72,0,16-.23h0a1.58,1.58,0,0,0,1.56-1.67V40.22a1.54,1.54,0,0,0-1.63-1.6" fill={props.primaryColor}/>
      <path d="M140.79,11.79a8.18,8.18,0,0,0-3.5-.82A8.1,8.1,0,0,0,133.22,12a7.63,7.63,0,0,0-2.9,2.93,8.4,8.4,0,0,0,0,8.17,7.8,7.8,0,0,0,2.9,2.94,8,8,0,0,0,4.07,1.07,8.62,8.62,0,0,0,3.44-.75,9.7,9.7,0,0,0,3.05-2.09l2.71,3a14.2,14.2,0,0,1-4.38,3,12.33,12.33,0,0,1-5.06,1.11,12.54,12.54,0,0,1-6.32-1.64,12,12,0,0,1-4.51-4.46,12.13,12.13,0,0,1-1.65-6.24,11.87,11.87,0,0,1,1.68-6.22,12.07,12.07,0,0,1,4.56-4.41,13,13,0,0,1,6.41-1.61,12.86,12.86,0,0,1,5,1,12.35,12.35,0,0,1,4.2,2.83l-2.67,3.35a9,9,0,0,0-3-2.29" fill={props.primaryColor}/>
      <path d="M149.94,7.18h17.53v4H154.52v5.91h11.62v4H154.52v6h13.36v4H149.94V7.18M160.3,0l4.27,1.74-4.38,3.53-3.45-.35Z" fill={props.primaryColor}/>
      <path d="M188.63,19.21h4V28.3a15.74,15.74,0,0,1-4.44,2.28,15.57,15.57,0,0,1-5,.87,12.82,12.82,0,0,1-6.41-1.64,12.35,12.35,0,0,1-4.56-4.46,12,12,0,0,1-1.67-6.24,11.73,11.73,0,0,1,1.7-6.21,12.54,12.54,0,0,1,4.67-4.41,13.39,13.39,0,0,1,6.55-1.62,14.63,14.63,0,0,1,5.06.92,13.47,13.47,0,0,1,4.3,2.53l-2.59,3.39a9.91,9.91,0,0,0-3.17-2,9.55,9.55,0,0,0-3.6-.72,8.27,8.27,0,0,0-4.16,1.08,8.06,8.06,0,0,0-4.08,7.06,8,8,0,0,0,1.11,4.14,8.27,8.27,0,0,0,3,3,8.11,8.11,0,0,0,4.17,1.09,9.29,9.29,0,0,0,2.58-.39,11.29,11.29,0,0,0,2.58-1.11v-6.6" fill={props.primaryColor}/>
      <polyline points="197.62 7.18 215.16 7.18 215.16 11.18 202.2 11.18 202.2 17.09 213.83 17.09 213.83 21.09 202.2 21.09 202.2 27.11 215.57 27.11 215.57 31.11 197.62 31.11 197.62 7.18" fill={props.primaryColor}/>
      <path d="M237.22,9.33a7.62,7.62,0,0,1,2.48,6.09,8.18,8.18,0,0,1-2.48,6.37q-2.47,2.28-7,2.28H225v7h-4.58V7.18h9.81q4.52,0,7,2.15M234,18.9a4.06,4.06,0,0,0,1.38-3.35A3.94,3.94,0,0,0,234,12.27a6.31,6.31,0,0,0-4-1.09h-5V20h5A6.13,6.13,0,0,0,234,18.9Z" fill={props.primaryColor}/>
      <path d="M137.55,42.66a11.39,11.39,0,0,0-3.4-.61,4.76,4.76,0,0,0-2.6.61,1.93,1.93,0,0,0-1,1.71,2.23,2.23,0,0,0,.67,1.66,5.17,5.17,0,0,0,1.67,1.1c.67.28,1.59.59,2.75.94a29,29,0,0,1,4,1.43,7.23,7.23,0,0,1,2.65,2.12,5.91,5.91,0,0,1,1.09,3.69,6.28,6.28,0,0,1-1.18,3.81A7.36,7.36,0,0,1,139,61.6a12.28,12.28,0,0,1-4.73.86,14.81,14.81,0,0,1-5.47-1.05,15.41,15.41,0,0,1-4.65-2.78l1.91-3.9a13.86,13.86,0,0,0,4.07,2.63,10.84,10.84,0,0,0,4.21,1,5.3,5.3,0,0,0,3-.73,2.42,2.42,0,0,0,.43-3.7,5,5,0,0,0-1.69-1.07c-.68-.28-1.62-.58-2.8-.93a31.35,31.35,0,0,1-4-1.4,6.81,6.81,0,0,1-2.63-2.07,5.68,5.68,0,0,1-1.1-3.64,6,6,0,0,1,1.12-3.64,7.22,7.22,0,0,1,3.14-2.41,12.05,12.05,0,0,1,4.7-.85,16.12,16.12,0,0,1,4.6.68,15.65,15.65,0,0,1,4,1.85l-1.85,4a17.54,17.54,0,0,0-3.77-1.75" fill={props.primaryColor}/>
      <polyline points="147.78 38.26 152.37 38.26 152.37 48.48 164.29 48.48 164.29 38.26 168.88 38.26 168.88 62.18 164.29 62.18 164.29 52.48 152.37 52.48 152.37 62.18 147.78 62.18 147.78 38.26" fill={props.primaryColor}/>
      <path d="M191.85,62.18l-2.12-5.06H178l-2.12,5.06h-4.75l10.49-23.93h4.75l10.36,23.93h-4.92m-12.17-9h8.41l-4.17-10Z" fill={props.primaryColor}/>
      <polyline points="224.74 38.26 229.25 38.26 221.56 62.18 217.04 62.18 211.85 44.03 206.55 62.18 202.1 62.18 194.44 38.26 199.12 38.26 204.47 57.23 209.8 38.26 214.09 38.26 219.45 57.3 224.74 38.26" fill={props.primaryColor}/>
      <rect x="232.62" y="38.25" width="4.58" height="23.93" fill={props.primaryColor}/>
      <polyline points="259.28 38.26 263.72 38.26 263.72 62.18 259.38 62.18 247.07 45.71 247.07 62.18 242.6 62.18 242.6 38.26 246.94 38.26 259.28 54.77 259.28 38.26" fill={props.primaryColor}/>
      <rect x="269.12" y="38.25" width="4.58" height="23.93" fill={props.primaryColor}/>
      <path d="M295.34,50.29h4v9.09a16,16,0,0,1-4.44,2.27,15.64,15.64,0,0,1-5,.88,12.7,12.7,0,0,1-6.41-1.65,12.27,12.27,0,0,1-4.56-4.46,12,12,0,0,1-1.68-6.23A11.65,11.65,0,0,1,278.93,44a12.31,12.31,0,0,1,4.67-4.41A13.39,13.39,0,0,1,290.15,38a14.31,14.31,0,0,1,5.05.92,13.52,13.52,0,0,1,4.31,2.53l-2.6,3.38a9.9,9.9,0,0,0-3.16-2,9.59,9.59,0,0,0-3.6-.72A8.27,8.27,0,0,0,286,43.13a7.91,7.91,0,0,0-3,2.95,8.27,8.27,0,0,0,0,8.24,8.17,8.17,0,0,0,7.16,4.07,9.29,9.29,0,0,0,2.58-.39,11.79,11.79,0,0,0,2.58-1.12V50.29" fill={props.primaryColor}/>
      <path d="M321.53,62.18l-2.12-5.06H307.72l-2.12,5.06h-4.76l10.5-23.93h4.75l10.36,23.93h-4.92m-12.17-9h8.41l-4.18-10Z" fill={props.primaryColor}/>
      <polyline points="345.56 38.26 350 38.26 350 62.18 345.66 62.18 333.35 45.71 333.35 62.18 328.88 62.18 328.88 38.26 333.22 38.26 345.56 54.77 345.56 38.26" fill={props.primaryColor}/>
      <path d="M54.85,8.73a359.34,359.34,0,0,1,53.55,3.78.83.83,0,0,0,.94-.83v-4A4.14,4.14,0,0,0,106,3.34,375,375,0,0,0,54.85,0,386.18,386.18,0,0,0,5.59,3C4.06,3.26,0,4.21,0,8.57V61a5,5,0,0,0,4.53,5.16,384.37,384.37,0,0,0,50.32,3.17A375,375,0,0,0,106.21,66a3.69,3.69,0,0,0,3.13-3.46V57.67a.83.83,0,0,0-.94-.83,359.28,359.28,0,0,1-53.55,3.79A376.15,376.15,0,0,1,11.2,58.18a1.66,1.66,0,0,1-1.71-1.65V12.74A1.64,1.64,0,0,1,11,11.2l.17,0A376.22,376.22,0,0,1,54.85,8.73" fill={props.primaryColor}/>
      <path d="M68.51,38.62H22.9c-5.42,0-5.25-4.38-5.25-4.38V22.57a5.3,5.3,0,0,1,5-5.41c2.76-.33,19.63-1.25,29.86-1.25,7.59,0,15.07.07,22.35.48,4.8.3,5.2,3.47,5.22,4.3v3a.72.72,0,0,1-.76.72c-8.65-.6-17.64-.73-26.81-.73-8,0-15.69.29-23.32.75A1.47,1.47,0,0,0,27.68,26v3.26a1.39,1.39,0,0,0,1.58,1.39H74.75c5.52,0,5.38,4.51,5.38,4.51V47.27a5.21,5.21,0,0,1-5,5.49c-2.58.26-14.86.59-22.51.59-10.23,0-20.14-.47-29.74-1.21-5.54-.44-5.17-4.26-5.19-4.49V44.71a.69.69,0,0,1,.77-.68c10.89,1,22.32,1.51,34.16,1.51,5.39,0,10.72,0,16-.23h0a1.58,1.58,0,0,0,1.56-1.67V40.22a1.54,1.54,0,0,0-1.63-1.6" fill={props.primaryColor}/>
      <path d="M140.79,11.79a8.18,8.18,0,0,0-3.5-.82A8.1,8.1,0,0,0,133.22,12a7.63,7.63,0,0,0-2.9,2.93,8.4,8.4,0,0,0,0,8.17,7.8,7.8,0,0,0,2.9,2.94,8,8,0,0,0,4.07,1.07,8.62,8.62,0,0,0,3.44-.75,9.7,9.7,0,0,0,3.05-2.09l2.71,3a14.2,14.2,0,0,1-4.38,3,12.33,12.33,0,0,1-5.06,1.11,12.54,12.54,0,0,1-6.32-1.64,12,12,0,0,1-4.51-4.46,12.13,12.13,0,0,1-1.65-6.24,11.87,11.87,0,0,1,1.68-6.22,12.07,12.07,0,0,1,4.56-4.41,13,13,0,0,1,6.41-1.61,12.86,12.86,0,0,1,5,1,12.35,12.35,0,0,1,4.2,2.83l-2.67,3.35a9,9,0,0,0-3-2.29" fill={props.primaryColor}/>
      <path d="M149.94,7.18h17.53v4H154.52v5.91h11.62v4H154.52v6h13.36v4H149.94V7.18M160.3,0l4.27,1.74-4.38,3.53-3.45-.35Z" fill={props.primaryColor}/>
      <path d="M188.63,19.21h4V28.3a15.74,15.74,0,0,1-4.44,2.28,15.57,15.57,0,0,1-5,.87,12.82,12.82,0,0,1-6.41-1.64,12.35,12.35,0,0,1-4.56-4.46,12,12,0,0,1-1.67-6.24,11.73,11.73,0,0,1,1.7-6.21,12.54,12.54,0,0,1,4.67-4.41,13.39,13.39,0,0,1,6.55-1.62,14.63,14.63,0,0,1,5.06.92,13.47,13.47,0,0,1,4.3,2.53l-2.59,3.39a9.91,9.91,0,0,0-3.17-2,9.55,9.55,0,0,0-3.6-.72,8.27,8.27,0,0,0-4.16,1.08,8.06,8.06,0,0,0-4.08,7.06,8,8,0,0,0,1.11,4.14,8.27,8.27,0,0,0,3,3,8.11,8.11,0,0,0,4.17,1.09,9.29,9.29,0,0,0,2.58-.39,11.29,11.29,0,0,0,2.58-1.11v-6.6" fill={props.primaryColor}/>
      <polyline points="197.62 7.18 215.16 7.18 215.16 11.18 202.2 11.18 202.2 17.09 213.83 17.09 213.83 21.09 202.2 21.09 202.2 27.11 215.57 27.11 215.57 31.11 197.62 31.11 197.62 7.18" fill={props.primaryColor}/>
      <path d="M237.22,9.33a7.62,7.62,0,0,1,2.48,6.09,8.18,8.18,0,0,1-2.48,6.37q-2.47,2.28-7,2.28H225v7h-4.58V7.18h9.81q4.52,0,7,2.15M234,18.9a4.06,4.06,0,0,0,1.38-3.35A3.94,3.94,0,0,0,234,12.27a6.31,6.31,0,0,0-4-1.09h-5V20h5A6.13,6.13,0,0,0,234,18.9Z" fill={props.primaryColor}/>
      <path d="M137.55,42.66a11.39,11.39,0,0,0-3.4-.61,4.76,4.76,0,0,0-2.6.61,1.93,1.93,0,0,0-1,1.71,2.23,2.23,0,0,0,.67,1.66,5.17,5.17,0,0,0,1.67,1.1c.67.28,1.59.59,2.75.94a29,29,0,0,1,4,1.43,7.23,7.23,0,0,1,2.65,2.12,5.91,5.91,0,0,1,1.09,3.69,6.28,6.28,0,0,1-1.18,3.81A7.36,7.36,0,0,1,139,61.6a12.28,12.28,0,0,1-4.73.86,14.81,14.81,0,0,1-5.47-1.05,15.41,15.41,0,0,1-4.65-2.78l1.91-3.9a13.86,13.86,0,0,0,4.07,2.63,10.84,10.84,0,0,0,4.21,1,5.3,5.3,0,0,0,3-.73,2.42,2.42,0,0,0,.43-3.7,5,5,0,0,0-1.69-1.07c-.68-.28-1.62-.58-2.8-.93a31.35,31.35,0,0,1-4-1.4,6.81,6.81,0,0,1-2.63-2.07,5.68,5.68,0,0,1-1.1-3.64,6,6,0,0,1,1.12-3.64,7.22,7.22,0,0,1,3.14-2.41,12.05,12.05,0,0,1,4.7-.85,16.12,16.12,0,0,1,4.6.68,15.65,15.65,0,0,1,4,1.85l-1.85,4a17.54,17.54,0,0,0-3.77-1.75" fill={props.primaryColor}/>
      <polyline points="147.78 38.26 152.37 38.26 152.37 48.48 164.29 48.48 164.29 38.26 168.88 38.26 168.88 62.18 164.29 62.18 164.29 52.48 152.37 52.48 152.37 62.18 147.78 62.18 147.78 38.26" fill={props.primaryColor}/>
      <path d="M191.85,62.18l-2.12-5.06H178l-2.12,5.06h-4.75l10.49-23.93h4.75l10.36,23.93h-4.92m-12.17-9h8.41l-4.17-10Z" fill={props.primaryColor}/>
      <polyline points="224.74 38.26 229.25 38.26 221.56 62.18 217.04 62.18 211.85 44.03 206.55 62.18 202.1 62.18 194.44 38.26 199.12 38.26 204.47 57.23 209.8 38.26 214.09 38.26 219.45 57.3 224.74 38.26" fill={props.primaryColor}/>
      <rect x="232.62" y="38.25" width="4.58" height="23.93" fill={props.primaryColor}/>
      <polyline points="259.28 38.26 263.72 38.26 263.72 62.18 259.38 62.18 247.07 45.71 247.07 62.18 242.6 62.18 242.6 38.26 246.94 38.26 259.28 54.77 259.28 38.26" fill={props.primaryColor}/>
      <rect x="269.12" y="38.25" width="4.58" height="23.93" fill={props.primaryColor}/>
      <path d="M295.34,50.29h4v9.09a16,16,0,0,1-4.44,2.27,15.64,15.64,0,0,1-5,.88,12.7,12.7,0,0,1-6.41-1.65,12.27,12.27,0,0,1-4.56-4.46,12,12,0,0,1-1.68-6.23A11.65,11.65,0,0,1,278.93,44a12.31,12.31,0,0,1,4.67-4.41A13.39,13.39,0,0,1,290.15,38a14.31,14.31,0,0,1,5.05.92,13.52,13.52,0,0,1,4.31,2.53l-2.6,3.38a9.9,9.9,0,0,0-3.16-2,9.59,9.59,0,0,0-3.6-.72A8.27,8.27,0,0,0,286,43.13a7.91,7.91,0,0,0-3,2.95,8.27,8.27,0,0,0,0,8.24,8.17,8.17,0,0,0,7.16,4.07,9.29,9.29,0,0,0,2.58-.39,11.79,11.79,0,0,0,2.58-1.12V50.29" fill={props.primaryColor}/>
      <path d="M321.53,62.18l-2.12-5.06H307.72l-2.12,5.06h-4.76l10.5-23.93h4.75l10.36,23.93h-4.92m-12.17-9h8.41l-4.18-10Z" fill={props.primaryColor}/>
      <polyline points="345.56 38.26 350 38.26 350 62.18 345.66 62.18 333.35 45.71 333.35 62.18 328.88 62.18 328.88 38.26 333.22 38.26 345.56 54.77 345.56 38.26" fill={props.primaryColor}/>
    </svg>
  )
}

const Rtcs = (props) => {
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 94.21">
      <path d="M209.49,62.06C207.21,67.53,205,73.59,205,76.6l21.51-29.55c-3.73-2.89-14-8.36-26.54-8.34-10.12,0-21.73,3.49-32.82,14.73,7.07-3.54,18.35-9.59,30-9.61h0a27.83,27.83,0,0,1,16.61,5.25,1.06,1.06,0,0,1,.4.9,17.51,17.51,0,0,1-1.49,4.59c-.86,2.11-2,4.75-3.15,7.49" fill={props.secondaryColor}/>
      <path d="M250.26,1.39h4.6c3.7,0,7.27.6,7.27,5.08a4.37,4.37,0,0,1-3.87,4.27v0c.84.32,1.24.72,1.83,1.88l3.54,7.46h-3.06l-2.87-6.5c-.83-1.82-1.72-1.82-3.14-1.82H253v8.32h-2.69ZM253,9.45h2.28c2.55,0,4.06-1.13,4.06-2.93S258,3.76,255.53,3.76H253Z" fill={props.primaryColor}/>
      <path d="M277.09,19.57a10.49,10.49,0,0,1-4.33.89c-4.86,0-7.08-3-7.08-7.42,0-4,2.55-6.92,6.41-6.92,4.56,0,6,3.33,6,7.73h-9.77a4.48,4.48,0,0,0,4.51,4.56A8.1,8.1,0,0,0,277.09,17ZM275.45,12c0-2.09-1.23-3.81-3.41-3.81-2.47,0-3.49,2-3.68,3.81ZM272.52.67h2.93l-3.52,3.87h-1.8Z" fill={props.primaryColor}/>
      <path d="M293.63,19.54c0,4.16-2.15,6.55-7.06,6.55a13.91,13.91,0,0,1-4.92-.94l.27-2.33a10.49,10.49,0,0,0,4.94,1.23c2.66,0,4.24-1.8,4.24-4.7V18h-.05a5.69,5.69,0,0,1-4.59,2.12c-3.92,0-5.85-3.19-5.85-6.74s2-7.27,6.12-7.27c2.41,0,3.76.88,4.51,2.36h.05v-2h2.34Zm-2.53-6.09c0-2.85-1.31-5.29-4-5.29s-3.81,2.77-3.81,5.1c0,2.58,1.45,4.83,3.81,4.83s4-2.09,4-4.64" fill={props.primaryColor}/>
      <path d="M300.93,3.38h-2.84V.53h2.84Zm-2.68,3.06h2.52V20.13h-2.52Z" fill={props.primaryColor}/>
      <path d="M315.89,19.57a10.52,10.52,0,0,1-4.33.88c-4.85,0-7.08-2.95-7.08-7.41,0-4,2.55-6.92,6.42-6.92,4.56,0,6,3.33,6,7.73h-9.77a4.48,4.48,0,0,0,4.51,4.56A8.14,8.14,0,0,0,315.89,17ZM314.25,12c0-2.09-1.24-3.81-3.41-3.81-2.47,0-3.49,2-3.68,3.81Z" fill={props.primaryColor}/>
      <path d="M337.47,18.2h-.05a5.5,5.5,0,0,1-4.67,2.26c-4.13,0-5.88-3.68-5.88-7.41s1.93-6.93,5.91-6.93a4.86,4.86,0,0,1,4.53,2.2h.06V0h2.52V20.13h-2.42Zm-4.13.21c2.85,0,4-2.81,4-5.1,0-2.54-1.32-5.15-4.06-5.15s-3.81,2.58-3.75,5.07c-.06,2.15,1.07,5.18,3.78,5.18" fill={props.primaryColor}/>
      <path d="M354.69,19.57a10.51,10.51,0,0,1-4.32.88c-4.87,0-7.09-2.95-7.09-7.41,0-4,2.55-6.92,6.41-6.92,4.57,0,6,3.33,6,7.73H346a4.48,4.48,0,0,0,4.51,4.56A8.1,8.1,0,0,0,354.69,17ZM353.05,12c0-2.09-1.24-3.81-3.41-3.81-2.47,0-3.49,2-3.68,3.81Z" fill={props.primaryColor}/>
      <path d="M373.86,8.48h-3.62V16a2.16,2.16,0,0,0,2.12,2.44,3.08,3.08,0,0,0,1.71-.48v2.15a7.91,7.91,0,0,1-2.14.37c-2.6,0-4.22-1.23-4.22-4v-8h-3.09v-2h3.09V3.27l2.53-.8v4h3.62Z" fill={props.primaryColor}/>
      <path d="M377,6.44h2.36V8.56h.05A4.19,4.19,0,0,1,383,6.12a7,7,0,0,1,1.67.19V8.56a3.72,3.72,0,0,0-1.4-.24c-2.25,0-3.73,2.15-3.73,5.59v6.22H377Z" fill={props.primaryColor}/>
      <path d="M388.12,7.41a8.72,8.72,0,0,1,4.51-1.29c3.92,0,5.5,1.93,5.5,5.66v5.67a20.11,20.11,0,0,0,.11,2.68h-2.36V18.36h-.05a5.12,5.12,0,0,1-4.25,2.09c-2.95,0-5-1.28-5-4.27,0-3.43,3.76-4.5,6.37-4.5,1,0,1.69,0,2.68.05,0-2.36-.86-3.57-3.38-3.57a6.49,6.49,0,0,0-4,1.39Zm7.49,6.2c-.54,0-1.07,0-1.61,0-1.37,0-4.75.21-4.75,2.62a2.4,2.4,0,0,0,2.55,2.23,3.45,3.45,0,0,0,3.81-3.59Z" fill={props.primaryColor}/>
      <path d="M402.4,6.44h2.45V8.61h0a4.93,4.93,0,0,1,4.48-2.49c3.55,0,5.13,2.2,5.13,5.88v8.13H412V13c0-3.19-.7-4.75-2.9-4.88-2.88,0-4.16,2.31-4.16,5.64v6.33H402.4Z" fill={props.primaryColor}/>
      <path d="M417.79,17.45a6.74,6.74,0,0,0,3.14,1c1.15,0,2.58-.48,2.58-2,0-2.49-5.94-2.28-5.94-6.12,0-2.84,2.12-4.21,4.84-4.21a10.43,10.43,0,0,1,3.14.54l-.22,2.2a7.3,7.3,0,0,0-2.65-.7c-1.3,0-2.42.54-2.42,1.66,0,2.8,5.93,2,5.93,6.36,0,2.93-2.33,4.27-4.77,4.27a7.81,7.81,0,0,1-3.76-.75Z" fill={props.primaryColor}/>
      <path d="M429.25,6.44h2.42V8.37h.05a5.5,5.5,0,0,1,4.65-2.25c4,0,5.91,3.25,5.91,6.93s-1.75,7.4-5.88,7.4c-2.42,0-3.79-.94-4.57-2.2h-.06v7.52h-2.52Zm6.58,1.72c-2.73,0-4.06,2.6-4.06,5.15,0,2.29,1.19,5.1,4,5.1s3.84-3,3.79-5.18c.05-2.47-1-5.07-3.76-5.07" fill={props.primaryColor}/>
      <path d="M451.73,6.12c4.16,0,6.95,3,6.95,7.17a7,7,0,1,1-13.93,0c0-4.16,2.79-7.17,7-7.17m0,12.29c2.95,0,4.26-2.68,4.26-5.12,0-2.61-1.58-5.13-4.26-5.13s-4.3,2.52-4.3,5.13c0,2.44,1.32,5.12,4.3,5.12" fill={props.primaryColor}/>
      <path d="M462.07,6.44h2.36V8.56h.05a4.18,4.18,0,0,1,3.57-2.44,7,7,0,0,1,1.67.19V8.56a3.72,3.72,0,0,0-1.4-.24c-2.25,0-3.73,2.15-3.73,5.59v6.22h-2.52Z" fill={props.primaryColor}/>
      <path d="M479.78,8.48h-3.62V16a2.17,2.17,0,0,0,2.12,2.44,3.15,3.15,0,0,0,1.72-.48v2.15a7.92,7.92,0,0,1-2.15.37c-2.6,0-4.21-1.23-4.21-4v-8h-3.09v-2h3.09V3.27l2.52-.8v4h3.62Z" fill={props.primaryColor}/>
      <path d="M260.68,45.21a10.43,10.43,0,0,1-4.32.89c-4.86,0-7.09-3-7.09-7.41,0-4,2.55-6.93,6.42-6.93,4.56,0,6,3.34,6,7.74H252a4.48,4.48,0,0,0,4.52,4.56,8.1,8.1,0,0,0,4.21-1.37ZM259,37.62c0-2.1-1.23-3.81-3.41-3.81-2.47,0-3.48,2-3.68,3.81Z" fill={props.primaryColor}/>
      <path d="M265.12,32.09h2.44v2.17h.05a4.91,4.91,0,0,1,4.48-2.49c3.55,0,5.13,2.2,5.13,5.87v8.14H274.7V38.69c0-3.19-.7-4.75-2.9-4.88-2.87,0-4.16,2.3-4.16,5.63v6.34h-2.52Z" fill={props.primaryColor}/>
      <path d="M297.68,34.4a7.13,7.13,0,0,0-2.73-.59c-2.83,0-4.41,2.52-4.41,5.12,0,2.44,1.31,5.13,4.56,5.13a5.28,5.28,0,0,0,2.72-.78l.18,2.26a7.71,7.71,0,0,1-3.17.56,6.92,6.92,0,0,1-7-7.17c0-4.16,2.79-7.17,7-7.17a9.62,9.62,0,0,1,3.07.54Z" fill={props.primaryColor}/>
      <path d="M307,31.76c4.16,0,6.95,3,6.95,7.17a7,7,0,1,1-13.93,0c0-4.16,2.8-7.17,7-7.17m0,12.3c3,0,4.27-2.68,4.27-5.13s-1.58-5.12-4.27-5.12-4.29,2.52-4.29,5.12S304,44.06,307,44.06" fill={props.primaryColor}/>
      <path d="M317.28,32.09h2.37v2h.05a4.64,4.64,0,0,1,4.35-2.3,4,4,0,0,1,3.89,2.65,4.87,4.87,0,0,1,4.24-2.65c3.5,0,4.76,2.25,4.76,5.23v8.78h-2.52V37.62c0-1.69-.41-3.81-2.45-3.81-2.58,0-3.6,2.52-3.6,5.07v6.9h-2.52V37.62c0-1.69-.41-3.81-2.45-3.81-2.57,0-3.59,2.52-3.59,5.07v6.9h-2.53Z" fill={props.primaryColor}/>
      <path d="M341.16,32.09h2.36v2h.05a4.64,4.64,0,0,1,4.35-2.3,4,4,0,0,1,3.89,2.65,4.88,4.88,0,0,1,4.25-2.65c3.49,0,4.75,2.25,4.75,5.23v8.78h-2.53V37.62c0-1.69-.4-3.81-2.44-3.81-2.58,0-3.6,2.52-3.6,5.07v6.9h-2.52V37.62c0-1.69-.4-3.81-2.45-3.81-2.57,0-3.59,2.52-3.59,5.07v6.9h-2.52Z" fill={props.primaryColor}/>
      <path d="M377.16,45.78h-2.44V43.6h-.06a4.91,4.91,0,0,1-4.48,2.5c-3.54,0-5.13-2.2-5.13-5.88V32.09h2.53v7.08c0,3.2.69,4.76,2.89,4.89,2.88,0,4.17-2.31,4.17-5.64V32.09h2.52Z" fill={props.primaryColor}/>
      <path d="M381.46,32.09h2.44v2.17H384a4.93,4.93,0,0,1,4.49-2.49c3.54,0,5.12,2.2,5.12,5.87v8.14H391V38.69c0-3.19-.7-4.75-2.9-4.88-2.87,0-4.16,2.3-4.16,5.63v6.34h-2.52Z" fill={props.primaryColor}/>
      <path d="M415,43.85H415a5.46,5.46,0,0,1-4.66,2.25c-4.14,0-5.88-3.68-5.88-7.41s1.93-6.93,5.9-6.93a4.89,4.89,0,0,1,4.54,2.2h.05V25.65h2.53V45.78H415Zm-4.13.21c2.84,0,4-2.82,4-5.1,0-2.55-1.31-5.15-4.05-5.15s-3.81,2.57-3.76,5.07c-.05,2.15,1.08,5.18,3.79,5.18" fill={props.primaryColor}/>
      <path d="M432.23,45.21a10.43,10.43,0,0,1-4.32.89c-4.86,0-7.09-3-7.09-7.41,0-4,2.55-6.93,6.42-6.93,4.56,0,6,3.34,6,7.74h-9.77A4.48,4.48,0,0,0,428,44.06a8.06,8.06,0,0,0,4.21-1.37Zm-1.63-7.59c0-2.1-1.24-3.81-3.42-3.81-2.47,0-3.48,2-3.67,3.81Z" fill={props.primaryColor}/>
      <path d="M258.94,55.51a8.25,8.25,0,0,0-3.52-.78c-1.5,0-3.27.53-3.27,2.49,0,3.6,8,2.77,8,8.94,0,4-3.09,5.59-6.85,5.59a12.68,12.68,0,0,1-3.84-.78l.27-2.5a11.24,11.24,0,0,0,3.89.92c1.54,0,3.68-.86,3.68-3.07,0-4.18-8.05-3-8.05-8.85,0-3.34,2.63-5.11,6-5.11a12.66,12.66,0,0,1,4,.67Z" fill={props.primaryColor}/>
      <path d="M263.61,51.29h2.52V59.7h.06a5,5,0,0,1,4.4-2.29c3.54,0,5.13,2.2,5.13,5.88v8.14h-2.53V64.34c0-3.2-.7-4.75-2.89-4.89-2.88,0-4.17,2.31-4.17,5.64v6.34h-2.52Z" fill={props.primaryColor}/>
      <path d="M280.66,58.7a8.77,8.77,0,0,1,4.51-1.29c3.92,0,5.5,1.93,5.5,5.67v5.66a22.4,22.4,0,0,0,.11,2.69h-2.36V69.65h-.06a5.08,5.08,0,0,1-4.24,2.1c-2.95,0-5-1.29-5-4.27,0-3.44,3.76-4.51,6.37-4.51,1,0,1.69,0,2.68.05,0-2.36-.86-3.57-3.38-3.57a6.41,6.41,0,0,0-4,1.4Zm7.49,6.2c-.53,0-1.08-.05-1.61-.05-1.37,0-4.75.21-4.75,2.63a2.41,2.41,0,0,0,2.55,2.23,3.46,3.46,0,0,0,3.81-3.6Z" fill={props.primaryColor}/>
      <polygon points="310.44 71.42 307.51 71.42 303.97 59.93 303.91 59.93 300.45 71.42 297.52 71.42 293.12 57.73 295.89 57.73 299.02 69.06 299.08 69.06 302.57 57.73 305.55 57.73 308.98 69.06 309.04 69.06 312.37 57.73 314.84 57.73 310.44 71.42" fill={props.primaryColor}/>
      <path d="M320.32,54.67h-2.85V51.83h2.85Zm-2.69,3.06h2.53v13.7h-2.53Z" fill={props.primaryColor}/>
      <path d="M324.78,57.73h2.45v2.18h0a4.93,4.93,0,0,1,4.49-2.5c3.54,0,5.12,2.2,5.12,5.88v8.14h-2.52V64.34c0-3.2-.7-4.75-2.9-4.89-2.87,0-4.16,2.31-4.16,5.64v6.34h-2.52Z" fill={props.primaryColor}/>
      <path d="M344.19,54.67h-2.85V51.83h2.85Zm-2.69,3.06H344v13.7H341.5Z" fill={props.primaryColor}/>
      <path d="M360.75,70.83c0,4.16-2.14,6.55-7.05,6.55a13.82,13.82,0,0,1-4.92-.93l.27-2.34A10.51,10.51,0,0,0,354,75.35c2.65,0,4.24-1.8,4.24-4.7V69.3h-.05a5.72,5.72,0,0,1-4.6,2.13c-3.91,0-5.84-3.2-5.84-6.74s2-7.28,6.12-7.28c2.41,0,3.75.89,4.51,2.36h0v-2h2.34Zm-2.52-6.09c0-2.85-1.31-5.29-4-5.29s-3.81,2.77-3.81,5.1c0,2.58,1.45,4.84,3.81,4.84s4-2.1,4-4.65" fill={props.primaryColor}/>
      <path d="M365.7,58.7a8.72,8.72,0,0,1,4.51-1.29c3.92,0,5.5,1.93,5.5,5.67v5.66a22.4,22.4,0,0,0,.11,2.69h-2.37V69.65h-.05a5.08,5.08,0,0,1-4.24,2.1c-3,0-5-1.29-5-4.27,0-3.44,3.76-4.51,6.37-4.51,1,0,1.68,0,2.68.05,0-2.36-.86-3.57-3.39-3.57a6.43,6.43,0,0,0-4,1.4Zm7.49,6.2c-.54,0-1.08-.05-1.61-.05-1.37,0-4.76.21-4.76,2.63a2.42,2.42,0,0,0,2.56,2.23,3.46,3.46,0,0,0,3.81-3.6Z" fill={props.primaryColor}/>
      <path d="M380,57.73h2.45v2.18h.05a4.92,4.92,0,0,1,4.48-2.5c3.55,0,5.13,2.2,5.13,5.88v8.14h-2.52V64.34c0-3.2-.7-4.75-2.9-4.89-2.88,0-4.17,2.31-4.17,5.64v6.34H380Z" fill={props.primaryColor}/>
      <path d="M46.75,51.7C45,45.23,42.33,40.63,37.5,38.65v-.31c6-2.19,12.23-8.36,12.23-17.23,0-6.48-2.26-11.38-6.47-14.73C38.32,2.32,31.14.75,20.86.75A120.82,120.82,0,0,0,0,2.32V71.64H15.52V44h4.62c6.47.11,9.45,2.4,11.4,11.28,2,8.67,3.71,14.41,4.73,16.39h16C51,68.92,48.91,59.74,46.75,51.7M21.68,32.49H15.52V13.18a30,30,0,0,1,7.08-.53c7.2,0,11.51,3.34,11.51,9.71,0,6.16-4.62,10.13-12.43,10.13" fill={props.primaryColor}/>
      <path d="M68.55,59.28a52.54,52.54,0,0,1,15.62-5.82V14.63h18.9V1.27H49.85V14.63h18.7Z" fill={props.primaryColor}/>
      <path d="M102.93,52.76a78.57,78.57,0,0,1,21.61,5.91,118.86,118.86,0,0,0,29.06,8.56l-1.8-10a36.67,36.67,0,0,1-13.15,2.3c-13.88,0-22.09-8.88-22.09-23,0-15.76,9.66-23.38,22-23.38a32.7,32.7,0,0,1,13.16,2.6L155,3.26C152.21,1.79,146.05.12,137.93.12c-21,0-37.81,13.26-37.81,37.27a40.71,40.71,0,0,0,2.81,15.37" fill={props.primaryColor}/>
      <path d="M197.1,45.39A40.75,40.75,0,0,0,186,47.08,6.47,6.47,0,0,1,188.17,52c0,4.7-3.79,7.73-11,7.73a39.08,39.08,0,0,1-17.36-4.39l-3,12.28a71.82,71.82,0,0,0,7.5.41c16.14,0,29-5.81,39.6-16.51l.17-.17c0-.13,0-.26,0-.39a22.13,22.13,0,0,0-.51-4.85,28.52,28.52,0,0,0-6.49-.73" fill={props.primaryColor}/>
      <path d="M176.56,42.2c.62.21,1.19.43,1.75.64a43.91,43.91,0,0,1,20.31-5.67c-2.91-2.88-7-5.27-12.39-7.29-9-3.55-12.85-5.54-12.85-10,0-3.55,3.29-6.79,10.07-6.79a31.91,31.91,0,0,1,14.38,3.34l3.5-12.73c-4.11-2-9.76-3.56-17.68-3.56-16.23,0-26.2,9.09-26.2,21.09,0,10.34,7.6,16.81,19.11,21" fill={props.primaryColor}/>
      <path d="M164.32,71.15c-12,0-25.58-3.1-41-9.62a71.16,71.16,0,0,0-27.77-6.1c-24.63,0-43.08,15.76-51.27,38.78,6-7.17,11.87-13,18.21-17.3a47.8,47.8,0,0,1,27.88-8.39c11.13,0,24.29,3,41,9.25a80.53,80.53,0,0,0,27.88,5c11.89,0,23.1-2.66,31.19-7.56,7.51-4.57,12.3-10.86,13-18.87a56.33,56.33,0,0,1-39.12,14.86M53.73,82.1c-1.78,2-3.39,3.94-4.82,5.81a.57.57,0,0,1-.47.23.56.56,0,0,1-.35-.11.58.58,0,0,1-.11-.82c1.44-1.9,3.07-3.9,4.88-5.9a.6.6,0,0,1,.83,0,.59.59,0,0,1,0,.83M64.87,72A67.08,67.08,0,0,0,59,76.73a.54.54,0,0,1-.39.16.61.61,0,0,1-.43-.19.59.59,0,0,1,0-.83A67.8,67.8,0,0,1,64.18,71a.59.59,0,0,1,.82.13.6.6,0,0,1-.13.82m13.21-7a47.47,47.47,0,0,0-6.86,3h0a.57.57,0,0,1-.28.08.61.61,0,0,1-.51-.31.58.58,0,0,1,.24-.79,47.06,47.06,0,0,1,7-3.11.58.58,0,0,1,.74.35.59.59,0,0,1-.35.75m14.7-2.32-1.12,0a41.2,41.2,0,0,0-6.34.5h-.09a.58.58,0,0,1-.09-1.16,42.19,42.19,0,0,1,6.52-.52l1.15,0a.58.58,0,0,1,.57.6.59.59,0,0,1-.6.57m15.09,1.72h-.11c-2.27-.42-4.75-.79-7.46-1.1a.58.58,0,1,1,.12-1.16c2.75.31,5.25.68,7.55,1.12a.58.58,0,0,1-.1,1.15m14.59,4.12a.54.54,0,0,1-.22,0c-2.14-.83-4.48-1.65-7.14-2.39a.58.58,0,0,1-.4-.72.59.59,0,0,1,.72-.41c2.7.76,5.07,1.58,7.25,2.43a.59.59,0,0,1,.33.76.58.58,0,0,1-.54.37m14,5.92a.76.76,0,0,1-.21,0c-2.39-.87-4.66-1.89-7-3a.58.58,0,0,1,.48-1.06c2.37,1.07,4.61,2.07,7,2.92A.57.57,0,0,1,137,74a.59.59,0,0,1-.54.38m14.82,3.14h-.07c-2.7-.34-5.23-.77-7.54-1.2h0a.59.59,0,1,1,.22-1.15c2.3.43,4.8.85,7.46,1.19a.58.58,0,0,1,.51.65.59.59,0,0,1-.58.51m15.21.51c-1.66.12-3.29.17-4.91.17-.92,0-1.84,0-2.75,0a.59.59,0,0,1,0-1.17c.9,0,1.8,0,2.71,0,1.59,0,3.2,0,4.82-.17a.59.59,0,1,1,.09,1.17m15-3.2a49.7,49.7,0,0,1-7.38,2.14h-.12a.59.59,0,0,1-.57-.47.58.58,0,0,1,.45-.68A50.76,50.76,0,0,0,181,73.71a.58.58,0,0,1,.75.34.57.57,0,0,1-.34.75m13.3-7.59a48.26,48.26,0,0,1-6.34,4.34.59.59,0,0,1-.29.07.57.57,0,0,1-.51-.29.59.59,0,0,1,.22-.8A48.47,48.47,0,0,0,194,66.31a.58.58,0,1,1,.73.9" fill={props.primaryColor}/>
    </svg>
  )
}

export {Rtcs, Cgp};