import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {colors} from '../../style/constants';
import {Rtcs, Cgp} from '../../components/svgs/Logo';
import SearchButton from '../../components/svgs/SearchButton';
import links from './links';
import {Link} from 'react-router-dom';
import Background from '../../img/home.jpg';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundImage: 'url(' + Background + ')',
    backgroundSize: 'cover',
    paddingTop: 450,
    '& > div': {
      width: '50%',
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > div': {
        display: 'flex',
        height: 290,
        marginBottom: 40,
        '& li': {
          margin: '20px 0 40px 0',
          '& a': {
            display : 'flex',
            alignItems: 'flex-start',
            color: colors.WHITE,
            fontSize: 35,
            fontWeight: 700,
            '& > :last-child': {
              maxWidth: 300,
              paddingTop: 20
            },
            '& .clickable': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 85,
              height: 85,
              border: "3px solid",
              borderColor: colors.YELLOW,
              borderRadius: '50%',
              padding: 15,
              boxSizing: 'border-box',
              margin: '0 30px',
              animation: 'inAndOut 2s ease-in-out infinite',
              '& svg': {
                width: '100%',
                height: '100%',
              }
            }
          }
        }
      }
    },
  },
  logo: {
    height: 140,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    '& svg': {
      height: "100%",
      paddingTop: 50
    },
    '&::before': {
      content: '""',
      borderTop: '1px solid #fff',
      width: 250,
      display: 'block',
    },
  },
  rtcs: {
    left: 0,
    '& .clickable': {
      background: colors.RTCS,
    }
  },
  cgp: {
    right: 0,
    '& .clickable': {
      background: colors.BLUE,
    }
  }
}));

const Home = () => {
  
  const renderLinks = (links) => {
    return links.map((link, index) => {
      return (
        <li key={index}>
          <Link to={link.destination}>
            <span className ="clickable">{link.picto}</span>
            <span>{link.title}</span>
          </Link>
        </li>
      );
    });
  }

  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.rtcs}>
        <div>
          <SearchButton />  
          <ul>
            {renderLinks(links.rtcs)}
          </ul>
        </div>
        <span className={classes.logo}>
          <Rtcs primaryColor={colors.WHITE} secondaryColor={colors.YELLOW}/>
        </span>
      </div>   
      <div className={classes.cgp}>
        <div>
          <SearchButton />  
          <ul>
            {renderLinks(links.cgp)}
          </ul>
        </div>
        <span className={classes.logo}>
          <Cgp primaryColor={colors.WHITE} secondaryColor={colors.YELLOW}/>
        </span>
      </div>        
    </div>
  )
}

export default Home