import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Nav from '../../components/Nav/';
import {colors} from '../../style/constants';

const useStyles = makeStyles(() => ({
 wrapper: {
  width: '100vw',
  height: '100vh',
  position: 'relative',
  '& iframe': {
    width: '100%',
    height: '100%'
  },
  '& > :last-child': {
    left: '1465px!important',
    bottom: '0!important',
    background: colors.RTCS + '!important',
  }
 }
}));

const Rtcs = () => {

  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <iframe className={classes.iframe} frameBorder="0" src="https://rtcs.attractif.io/web/Proximap/views/terminal.php?ids-stop=213,213" allow="geolocation"/>
      <Nav color={colors.WHITE}/>
    </div>
  )
}

export default Rtcs