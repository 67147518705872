import React from 'react';
import {Routes, People, Departments} from '../../components/svgs/HomeLinks';

const links = {
  rtcs: [
    {
      title: 'Un trajet, un horaire ou de l\'information',
      destination: '/rtcs',
      picto: <Routes/>
    }
  ],
  cgp: [
  {
    title: 'Une personne',
    destination: '/people',
    picto: <People/>
  },
  {
    title: 'Un département ou un local',
    destination: '/departments',
    picto: <Departments/>
  },
  ]
};

export default links;
