const departmentsList = [
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Affaires étudiantes et communication',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Aide financière aux études et logement',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Aide psychosociale et services adaptés',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Approvisionnement',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Association générale des étudiantes et étudiants du Cégep de Shawinigan',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Bibliothèque',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Cafétéria',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Carrefour de l\'information',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Centre d\'aide à la réussite',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Centre d\'aide en français',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Centre sportif',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Centre virtuel d\'immersion clinique',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'CNETE',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département d\'Administration',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département d\'Anglais',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département d\'Arts et lettres',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département d\'Éducation physique',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département de Biologie et biotechnologies',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département de Chimie',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département de Mathématiques',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département de Microédition édition et hypermédia',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département de Philosophie',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département de Physique',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département de Sciences humaines',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département de Soins infirmiers',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département de Soins préhospitaliers d\'urgence',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département de Techniques d\'éducation à l\'enfance',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département de Techniques de génie mécanique',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Département de Techniques de l\'informatique',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Direction des études',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Direction générale',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Fondation du Cégep de Shawinigan',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Gradins gymnase',
    local: '1149',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Gradins piscine',
    local: '1159',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Gymnase',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Imprimerie',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Librairie - Coopsco',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Organisation scolaire (Registrariat)',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Orientation scolaire et professionnelle',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Palestre',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Piscine',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Programmes et réussite',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Ressources matérielles',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Salle de rencontre - A',
    local: '1217E',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Salle de rencontre - B',
    local: '1217F',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Salle du conseil',
    local: '1183',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Salle Gilles-Grondin (Auditorium)',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Service d\'animation et développement pédagogiques',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Service de formation continue',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Service des ressources humaines et Secrétariat général',
    local: '',
    job: ''
  },
  {
    firstname: '',
    lastname: '',
    fullname: '',
    department: 'Service informatique',
    local: '',
    job: ''
  },
  {
    firstname: '',
   lastname: '',
   fullname: '',
    department: 'Services administratifs',
    local: '',
    job: ''
  }
]

export default departmentsList;