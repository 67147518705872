import React from 'react';
import {colors} from '../../../style/constants';
import { makeStyles } from '@material-ui/core/styles';
import Nav from '../../Nav/';

const useStyles = makeStyles(() => ({
  wrapper: {
    position:'relative',
    background: colors.WHITE,
    width: '490px',
    height: '670px',
    boxShadow: '0px 55px 50px 15px rgba(0,0,0,0.5)',
    paddingTop: 50,
    color: colors.DARK_GREY,
    '& > :last-child': {
      bottom: 90
    },
    '& p': {
      marginTop: 20,
    },
    '& > *': {
      width: '100%',
      padding: '0 50px'
    },
    '& ul': {
      width: 'calc(100% - 100px)!important',
      margin: '50px auto',
      borderBottom: '1px solid ' + colors.LIGHT_GREY,
      padding: '0 0 50px 0!important',
      '& li': {
        width: '100%',
        borderBottom: '1px solid ' + colors.LIGHT_GREY,
        display: 'flex',
        '& > *': {
          display: 'flex',
          alignItems: 'center',
          padding: '10px 0'
        },
        '& > :first-child': {
          width: '45%',
          paddingRight: 20,
        },
        '& > :last-child': {
          width: '55%',
          paddingLeft: 20,
          borderLeft: '1px solid ' + colors.LIGHT_GREY,
        }
      },
      '& > :last-child': {
        borderBottom: 'none',
      },
    } 
  },
  footer: {
    display: 'flex',
    '& > *': {
      borderLeft: '1px solid ' + colors.LIGHT_GREY,
      paddingLeft: 20,
    },
    '& > :first-child': {
      width: '55%',
    },
    '& > :last-child': {
      width: '45%',
      borderLeft: '1px solid ' + colors.LIGHT_GREY,
    }
  },
  qr: {
    '& img': {
      marginTop: 20,
    }
  }
}));

const Infos = (props) => {
  const classes = useStyles();

  const onBackButtonClick = (status) => {
    props.onBackNavClick(status);
  }

  const qrCodeUrl = 'https://cegepshawinigan.attractif.io/routes/' + props.infos.department.toLowerCase().normalize("NFD").replace(/[^a-zA-Z0-9]/g, "") + '.png';

  return (
    <div className={classes.wrapper}>
      <h2>BOTTIN<span>Résultat de recherche</span></h2>
      <ul>
        {props.infos.fullname ? 
          <li>
          <h3>Nom/Prénom</h3>  
          <span>{props.infos.fullname}</span>  
          </li>  
        : ''}
        {props.infos.department ? 
          <li>
            <h3>Département/Service</h3>  
            <span>{props.infos.department}</span>  
          </li>  
        : ''}
        {props.infos.poste ? 
          <li>
            <h3>Poste</h3>  
            <span>{props.infos.poste}</span>  
          </li>  
        : ''}
        {props.infos.local ? 
          <li>
            <h3>Local</h3>  
            <span>{props.infos.local}</span>  
          </li>  
        : ''}
        {props.infos.job ? 
          <li>
            <h3>Titre</h3>  
            <span>{props.infos.job}</span>  
          </li>  
        : ''}   
      </ul>
      <div className={classes.footer}>
        <div>
          <h3>CÉGEP DE SHAWINIGAN</h3>
          <p>2263, avenue du Collège Shawinigan (QC) G9N 6V8</p>
          <p>{'819 539-6401 \n information@cshawi.ca'}</p>
        </div>
        <div className={classes.qr}>
          <h3>CAPTEZ L’ITINÉRAIRE</h3>
          <img src={"https://api.qrserver.com/v1/create-qr-code/?size=135x135&data="+ qrCodeUrl} alt={props.infos.department}/>
        </div>
      </div>
      <Nav color={colors.LIGHT_BLUE} onClick={() => onBackButtonClick(false)}/>
    </div>
  )
}

export default Infos;