const colors = {
  BLUE: '#003c66',
  LIGHT_BLUE: '#2ad7f4',
  YELLOW: '#f3bf00',
  WHITE: '#ffffff',
  BLACK: '#000000',
  LIGHT_GREY: "#f2eff0",
  GREY: "#acb3bd",
  DARK_GREY: "#646464",
  RTCS: "#0079c0",
};

export { colors };