import React, {useState} from 'react';
import {colors} from '../../style/constants';
import { makeStyles } from '@material-ui/core/styles';
import SearchBar from './search-bar';
import List from './list';
import Keyboard from './keyboard';
import {ClearButton} from '../svgs/buttons';
import Nav from '../Nav/';

const useStyles = makeStyles(() => ({
  wrapper: {
    background: colors.WHITE,
    width: '865px',
    height: '810px',
    boxShadow: '0px 55px 50px 15px rgba(0,0,0,0.5)',
    paddingTop: 50,
    position: 'relative',
   '& > *': {
     width: '100%',
     padding: '0 100px'
   },
   '& h4': {
     textAlign: 'center',
     margin: '12.5px 0'
   },
  },
  searchBar: {
    position: 'relative',
    padding: '0!important',
    '& > svg': {
      position: 'absolute',
      width: 32,
      right: 110,
      top: 34
    }
  }
}));

const Box = (props) => {
  const classes = useStyles();

  const [filter, setFilter] = useState({
    value: '',
    reset: false,
  });

  const dataFilter = (term) => {
    setFilter({
      value: term,
      reset: false
    });
  }

  return (
    <div className={classes.wrapper}>
      <h2>BOTTIN<span>Effectuer une recherche</span></h2>
      <div className={classes.searchBar}>
        <SearchBar value={filter.value}/>
        <ClearButton onClick={() => setFilter({value: '', reset: true})}/>
      </div>
      <h4>Appuyez sur le local/ressource/département afin d'accéder au plan.</h4>
      <List data={props.data} dataType={props.dataType} filter={filter.value}/>
      <Keyboard reset={filter.reset} onSearchTermChange={term => dataFilter(term)}/>
      <Nav color={colors.LIGHT_BLUE}/>
    </div>
  )
}

export default Box;