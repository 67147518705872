import React from 'react';
import {colors} from '../../style/constants';

const GoUpButton = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27">
      <circle cx="13.5" cy="13.5" r="12.5" fill="none" stroke={colors.LIGHT_BLUE} strokeMiterlimit={10} strokeWidth="2px"/>
      <polyline points="7.25 16.36 13.5 10.12 19.75 16.36" fill="none" stroke={colors.LIGHT_BLUE} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px"/>
    </svg>
  )
}

const GoDownButton = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27">
      <circle cx="13.5" cy="13.5" r="12.5" fill="none" stroke={colors.LIGHT_BLUE} strokeMiterlimit={10} strokeWidth="2px"/>
      <polyline points="19.75 10.38 13.5 16.63 7.25 10.38" fill="none" stroke={colors.LIGHT_BLUE} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px"/>
    </svg>
  )
}

const ClearButton = (props) => {
  return (
    <svg className="clickable" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27" onClick={props.onClick}>
      <circle cx="13.5" cy="13.5" r="12.5" fill="none" stroke={colors.LIGHT_BLUE} strokeMiterlimit={10} strokeWidth="2px"/>
      <line x1="17.92" y1="9.08" x2="9.08" y2="17.92" fill="none" stroke={colors.LIGHT_BLUE} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px"/>
      <line x1="9.08" y1="9.08" x2="17.92" y2="17.92" fill="none" stroke={colors.LIGHT_BLUE} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px"/>
    </svg>
  )
}

const Home = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.89 27.89">
      <circle cx="13.95" cy="13.95" r="12.95" fill="none" stroke={props.color} strokeMiterlimit={10} strokeWidth="2px"/>
      <polyline points="7.47 12.78 13.95 6.31 20.42 12.78" fill="none" stroke={props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px"/>
      <polyline points="18.07 11.09 18.07 19.34 9.82 19.34 9.82 11.09" fill="none" stroke={props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px"/>
    </svg>
  )
}

const Back = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.89 27.89">
      <circle cx="13.95" cy="13.95" r="12.95" fill="none" stroke={props.color} strokeMiterlimit={10} strokeWidth="2px"/>
      <line x1="7.52" y1="13.95" x2="20.46" y2="13.95"  fill="none" stroke={props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px"/>
      <polyline points="13.9 20.42 7.43 13.95 13.9 7.47"  fill="none" stroke={props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px"/>
    </svg>

  )
}

export {GoUpButton, GoDownButton, ClearButton, Home, Back}