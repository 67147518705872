import React, {useEffect, useState} from 'react';
import Research from '../../components/Research';
import axios from 'axios';

const People = () => {
  const [data, setData] = useState('');
  const apiURL = "https://www.cegepshawinigan.ca/wp-json/attractif/v1/people";

  const getData = async () => {
    const response = await axios.get(apiURL);
    setData(response.data);
  };

  useEffect(() => {
    getData();
  }, []);
  
  return (
    <Research data={data}/>
  )
}

export default People