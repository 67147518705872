import React, { useState, useEffect, useRef } from 'react';
import {colors} from '../../style/constants';
import { makeStyles } from '@material-ui/core/styles';
import {GoUpButton, GoDownButton} from '../svgs/buttons';
import Result from './Result/';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: 200,
    overflowY: 'scroll',
    width: '100%',
    position: 'relative',
    paddingTop: '36px!important',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& li': {
      width: '100%',
      borderBottom: '1px solid ' + colors.GREY,
      display: 'flex',
      color: colors.DARK_GREY,
      '& span': {
        padding: 10,
        borderLeft: '1px solid ' + colors.GREY,
      },
      '& > :first-child': {
        width: '15%',
        borderLeft: 'none',
      },
      '& > :nth-child(2)': {
        width: '15%'
      },
      '& > :nth-child(3)': {
        width: '30%'
      },
      '& > :nth-child(4)': {
        width: '28%'
      },
      '& > :nth-child(5)': {
        width: '12%'
      },
    },
    '& li:nth-of-type(even)': {
      backgroundColor: colors.LIGHT_GREY,
    },
    '& .department': {
      '& > :nth-child(1), > :nth-child(2), > :nth-child(4), > :nth-child(5)': {
        display: 'none',
      },
      '& > :nth-child(3)': {
        width: '100%',
        borderLeft: 'none',
      },
    }
  },
  firstRow: {
    position: 'absolute',
    top: 0,
    left: 100,
    textTransform: 'uppercase',
    color: colors.LIGHT_BLUE + '!important',
    borderTop: '1px solid ' + colors.GREY,
    width: 'calc(100% - 200px)!important'
  },
  scrollNav: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      height: 32,
      width: 32,
      margin: '12.5px 5px 25px 5px',
      '& svg': {
        width: '100%'
      }
    }
  }
}));

const List = (props) => {
  const classes = useStyles();
  const ref = useRef(null);

  const [data, setData] = useState({
    items: props.data,
    active: ''
  });

    useEffect(() => {
      setData({
        items: props.data,
        active: ''
      })
  }, [props.data]);


  const renderData = () => {
    return data.items.map((item, index) => {
      if(item.firstname.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f-]/g, "").includes(props.filter) || item.lastname.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(props.filter) || item.fullname.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(props.filter) || item.department.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(props.filter) || item.job.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(props.filter) || item.local.toLowerCase().includes(props.filter) ) {
        return (
          <li className={props.dataType} key={index} onClick={() => setData({
            items: data.items,
            active: item
          })}>
            <span>{item.lastname}</span>
            <span>{item.firstname}</span>
            <span>{item.department}</span>
            <span>{item.job}</span>
            <span>{item.local}</span>
          </li>
        );
      }
    });
  }

  const setInactive = (status) => {
    setData({
      items: data.items,
      active: status
    });
  }

  const scroll = (scrollOffset) => {
    ref.current.scrollTop += scrollOffset;
  };

  return (
    <>
      <ul className={classes.wrapper} ref={ref}>
        <li className={classes.firstRow + ' ' + props.dataType}>
          <span>Nom</span>
          <span>Prénom</span>
          <span>Départment/Service</span>
          <span>Titre</span>
          <span>Local</span>
        </li>
        {data.items ? renderData() : ''}
      </ul>
      <div className={classes.scrollNav}>
        <button className="clickable" onClick={() => scroll(-200)}><GoUpButton /></button>
        <button className="clickable" onClick={() => scroll(200)}><GoDownButton/></button>
      </div>
      {data.active ? <Result infos={data.active} onBackNavClick={status => setInactive(status)}/> : ''}
    </>
  )
}

export default List;