
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {colors} from '../../style/constants';
import {Back} from '../svgs/buttons';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    color: colors.DARK_GREY,
    fontSize: 16,
    '& > div:nth-of-type(even)': {
      padding: '0 30px!important'
    },
    '& > :last-child': {
      border: colors.GREY + ' 1px solid',
      height: 45,
      justifyContent: 'center!important',
      alignItems: 'center',
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  key: {
    minWidth: 45,
    height: 45,
    textTransform: 'uppercase',
    border: colors.GREY + ' 1px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg': {
      width: 32,
      '& circle': {
        display: 'none'
      }
    }
  },
}));

const Keyboard = (props) => {

  const [value, setValue] = useState('');

  const onInputChange = (term) => {
    setValue(term);
    props.onSearchTermChange(term);
  }

  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.row}>
        <div className={classes.key + " clickable"} data-key="1" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>1</div>
        <div className={classes.key + " clickable"} data-key="2" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>2</div>
        <div className={classes.key + " clickable"} data-key="3" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>3</div>
        <div className={classes.key + " clickable"} data-key="4" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>4</div>
        <div className={classes.key + " clickable"} data-key="5" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>5</div>
        <div className={classes.key + " clickable"} data-key="6" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>6</div>
        <div className={classes.key + " clickable"} data-key="7" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>7</div>
        <div className={classes.key + " clickable"} data-key="8" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>8</div>
        <div className={classes.key + " clickable"} data-key="9" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>9</div>
        <div className={classes.key + " clickable"} data-key="0" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>0</div>
        <div className={classes.key + " clickable"} data-key="" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value.slice(0, -1))}><Back color={colors.LIGHT_BLUE}/></div>
      </div>    
      <div className={classes.row}>
        <div className={classes.key + " clickable"} data-key="q" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>q</div>
        <div className={classes.key + " clickable"} data-key="w" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>w</div>
        <div className={classes.key + " clickable"} data-key="e" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>e</div>
        <div className={classes.key + " clickable"} data-key="r" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>r</div>
        <div className={classes.key + " clickable"} data-key="t" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>t</div>
        <div className={classes.key + " clickable"} data-key="y" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>y</div>
        <div className={classes.key + " clickable"} data-key="u" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>u</div>
        <div className={classes.key + " clickable"} data-key="i" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>i</div>
        <div className={classes.key + " clickable"} data-key="o" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>o</div>
        <div className={classes.key + " clickable"} data-key="p" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>p</div>
      </div>                                                      
      <div className={classes.row}>
        <div className={classes.key + " clickable"} data-key="a" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>a</div>
        <div className={classes.key + " clickable"} data-key="s" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>s</div>
        <div className={classes.key + " clickable"} data-key="d" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>d</div>
        <div className={classes.key + " clickable"} data-key="f" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>f</div>
        <div className={classes.key + " clickable"} data-key="g" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>g</div>
        <div className={classes.key + " clickable"} data-key="h" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>h</div>
        <div className={classes.key + " clickable"} data-key="j" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>j</div>
        <div className={classes.key + " clickable"} data-key="k" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>k</div>
        <div className={classes.key + " clickable"} data-key="l" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>l</div>
        <div className={classes.key + " clickable"} data-key="-" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>-</div>
        <div className={classes.key + " clickable"} data-key="_" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>_</div>
      </div>
      <div className={classes.row}>
        <div className={classes.key + " clickable"} data-key="z" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>z</div>
        <div className={classes.key + " clickable"} data-key="x" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>x</div>
        <div className={classes.key + " clickable"} data-key="c" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>c</div>
        <div className={classes.key + " clickable"} data-key="v" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>v</div>
        <div className={classes.key + " clickable"} data-key="b" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>b</div>
        <div className={classes.key + " clickable"} data-key="n" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>n</div>
        <div className={classes.key + " clickable"} data-key="m" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>m</div>
        <div className={classes.key + " clickable"} data-key="." onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>.</div>
        <div className={classes.key + " clickable"} data-key="," onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>,</div>
        <div className={classes.key + " clickable"} data-key="@" onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>@</div>  
      </div>
      <div className={classes.row + " clickable"} data-key=" " onClick={event => onInputChange(props.reset ? event.target.getAttribute("data-key") : value + event.target.getAttribute("data-key"))}>Espace</div>                            
    </div>
  )
}

export default Keyboard;