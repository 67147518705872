import React from 'react';
import {colors} from '../../../style/constants';
import { makeStyles } from '@material-ui/core/styles';
import {ResultSun} from '../../svgs/Suns';
import {Cgp} from '../../svgs/Logo';
import Infos from './infos';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 999,
    width: '100vw!important',
    height: '100vh',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    background: colors.BLUE,
  },
  logo: {
    width: 350,
    marginTop: 100
  },
}));

const Result = (props) => {
  const setInactive = (status) => {
    props.onBackNavClick(status);
  }

  const backgroundUrl = '/routes/' + props.infos.department.toLowerCase().normalize("NFD").replace(/[^a-zA-Z0-9]/g, "") + '.png';

  const classes = useStyles();
  return (
    <div className={classes.wrapper} style={{backgroundImage: 'url(' + backgroundUrl + ')'}}>
      <ResultSun />
      <Cgp className={classes.logo} primaryColor={colors.WHITE} secondaryColor={colors.YELLOW}/>
      <Infos infos={props.infos} onBackNavClick={status => setInactive(status)}/>
    </div>
  )
}

export default Result;